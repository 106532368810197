import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime, distinctUntilChanged } from 'rxjs/operators';
import { to } from 'await-to-js';
import { CbAuthService } from '../../services/cb-auth.service';
import { regexPattern, CustomValidators } from '../../shared/custom-validations/custom-validations';
import { CbCreateAccountPageContent } from './create-account-content';
import { CbLoginConfig } from '../../services/cb-login.config';
import { CbRecaptchaService } from '../../services/cb-recpatcha.service';
import { CbBackendService } from '../../services/cb-backend.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbTrainingAppService } from '../../services/cb-training-app.service';
import { CbHttpErrorsService } from '../../services/cb-http-errors.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { getFriendlyErrorMessage } from './features';
var CreateAccountComponent = /** @class */ (function () {
    function CreateAccountComponent(fb, cbAuthService, cbBackendService, cbRecaptchaService, cbConfigLogin, cbAuthURLService, cbTrainingAppService, cbHttpErrorsService, cbFullstoryService) {
        this.fb = fb;
        this.cbAuthService = cbAuthService;
        this.cbBackendService = cbBackendService;
        this.cbRecaptchaService = cbRecaptchaService;
        this.cbConfigLogin = cbConfigLogin;
        this.cbAuthURLService = cbAuthURLService;
        this.cbTrainingAppService = cbTrainingAppService;
        this.cbHttpErrorsService = cbHttpErrorsService;
        this.cbFullstoryService = cbFullstoryService;
        this.destroy$ = new Subject();
        this.submitted = false;
        this.formLoading = false;
        this.captchaToken = null;
        this.recaptchTokenError = false;
        this.recaptchaLoading = true;
        this.cbCreateAccountContent = new CbCreateAccountPageContent();
    }
    CreateAccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fetchRecaptchaToken();
        this.captchaInterval = setInterval(function () {
            _this.fetchRecaptchaToken();
        }, 60000);
        this.initializeForm();
        this.handleFormChanges();
    };
    CreateAccountComponent.prototype.fetchRecaptchaToken = function () {
        var _this = this;
        this.recaptchaLoading = true;
        this.cbRecaptchaService
            .getCaptchaToken('secreateAccountviceDesk')
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (data) {
            _this.recaptchaLoading = false;
            _this.captchaToken = data;
            if (!data) {
                _this.handleRecaptchaError();
                return;
            }
        }, function (error) {
            console.log(error);
            _this.handleRecaptchaError();
            return;
        });
    };
    CreateAccountComponent.prototype.handleRecaptchaError = function () {
        this.recaptchTokenError = true;
    };
    CreateAccountComponent.prototype.initializeForm = function () {
        this.form = this.fb.group({
            firstName: [
                '',
                [
                    Validators.required,
                    Validators.pattern(regexPattern.namePattern),
                    CustomValidators.noWhitespaceValidator,
                    Validators.minLength(1),
                ],
            ],
            lastName: [
                '',
                [
                    Validators.required,
                    Validators.pattern(regexPattern.namePattern),
                    CustomValidators.noWhitespaceValidator,
                    Validators.minLength(1),
                ],
            ],
            username: [
                '',
                [
                    Validators.required,
                    CustomValidators.noStartWithNumber,
                    Validators.pattern(regexPattern.username),
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ],
            ],
            email: ['', [Validators.required, Validators.pattern(regexPattern.email)]],
            password: ['', [Validators.required, Validators.pattern(regexPattern.strongPassword)]],
        });
        this.firstName = this.form.get('firstName');
        this.lastName = this.form.get('lastName');
        this.username = this.form.get('username');
        this.email = this.form.get('email');
        this.password = this.form.get('password');
    };
    CreateAccountComponent.prototype.handleFormChanges = function () {
        var _this = this;
        var debouncePeriod = 500;
        this.form.valueChanges
            .pipe(takeUntil(this.destroy$), throttleTime(debouncePeriod), distinctUntilChanged())
            .subscribe(function () {
            _this.setGenericError({});
        });
    };
    // * NoContinue, means we will use a invalid State,
    // so the user will be redirecte to Login Page in order to continue
    // * NoContinue: Allow to send Email Verification on Create Account time
    // before a valid contine-State (auth0) is generated
    CreateAccountComponent.prototype.handleNoContinue = function () {
        if (this.cbTrainingAppService.IsTrainingCheckoutFlow()) {
            return true;
        }
        return false;
    };
    CreateAccountComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var state, authorizeURL, noContinue, user, _a, err, userResponse, errorMessage;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        state = this.cbAuthURLService.getAllURLParams().state;
                        authorizeURL = this.cbAuthURLService.getReturnPath();
                        noContinue = this.handleNoContinue();
                        user = {
                            username: this.username.value,
                            email: this.email.value,
                            password: this.password.value,
                            firstName: this.firstName.value,
                            lastName: this.lastName.value,
                            captchaToken: this.captchaToken,
                            state: state,
                            noContinue: noContinue,
                            returnTo: authorizeURL,
                        };
                        this.submitted = true;
                        this.setGenericError({});
                        if (!this.form.valid) {
                            return [2 /*return*/];
                        }
                        this.formLoading = true;
                        return [4 /*yield*/, to(this.cbBackendService.createAccount(user).toPromise())];
                    case 1:
                        _a = _b.sent(), err = _a[0], userResponse = _a[1];
                        if (err) {
                            this.fetchRecaptchaToken();
                            errorMessage = this.cbHttpErrorsService.GetErrorMessage(err);
                            this.errorMessage = getFriendlyErrorMessage(errorMessage);
                            this.cbFullstoryService.fsError(this.errorMessage);
                            this.formLoading = false;
                            return [2 /*return*/];
                        }
                        this.sendCreateAccountEvent();
                        this.onCreateAccountSuccess(userResponse);
                        return [2 /*return*/];
                }
            });
        });
    };
    CreateAccountComponent.prototype.sendCreateAccountEvent = function () {
        var payload = {
            username: this.username.value,
            email: this.email.value,
            displayName: this.firstName.value + " " + this.lastName.value,
        };
        this.cbFullstoryService.sendEvent('create_account', payload);
    };
    CreateAccountComponent.prototype.disableSubmit = function () {
        if (this.form.invalid) {
            return true;
        }
        var _a = this.form.value, firstName = _a.firstName, lastName = _a.lastName, username = _a.username, password = _a.password, email = _a.email;
        var isAllFieldsProvided = email && firstName && lastName && password && username && this.captchaToken;
        if (!isAllFieldsProvided) {
            return true;
        }
        if (this.submitted && (this.form.invalid || this.formLoading)) {
            return true;
        }
        return false;
    };
    CreateAccountComponent.prototype.onCreateAccountSuccess = function (response) {
        if (!response) {
            this.formLoading = false;
            this.errorMessage = 'Something went wrong!';
            return;
        }
        if (response.error) {
            this.formLoading = false;
            this.errorMessage = response.message;
            return;
        }
        var isAuth0App = this.cbConfigLogin.cbAuthCentralizedLogin;
        if (isAuth0App) {
            // @info: we keep loading up to auth0 rule makes the redirection
            this.handleCreateAccountOnAuth0App();
            return;
        }
        this.formLoading = false;
        this.handleCreateAccountAsAngularModule(response);
        return;
    };
    CreateAccountComponent.prototype.handleCreateAccountOnAuth0App = function () {
        var _this = this;
        // @Info: Login again we are running all Auth0 rules (Pipeline)
        // this action redirects to sso-static-app.com/cb/create-account-email' in the current tab
        this.cbAuthService.login(this.username.value, this.password.value).subscribe(function () { }, function () {
            _this.formLoading = false;
            _this.setGenericError({ errorMessage: 'There was an error creating your account.' });
            _this.cbFullstoryService.fsError('There was an error creating your account.');
        });
    };
    CreateAccountComponent.prototype.handleCreateAccountAsAngularModule = function (user) {
        this.cbAuthService.navigateTo('/cb/create-account-email', { queryParams: { email: user.email } });
    };
    CreateAccountComponent.prototype.onCreateAccountError = function (error) {
        this.formLoading = false;
        this.handleServerSideError(error);
    };
    CreateAccountComponent.prototype.handleServerSideError = function (error) {
        this.setGenericError(error);
    };
    CreateAccountComponent.prototype.setGenericError = function (_a) {
        var _b = _a.errorField, errorField = _b === void 0 ? null : _b, _c = _a.errorMessage, errorMessage = _c === void 0 ? null : _c;
        if (errorMessage) {
            this.cbFullstoryService.fsError(errorMessage);
        }
        this.errorField = errorField;
        this.errorMessage = errorMessage;
    };
    CreateAccountComponent.prototype.githubLogin = function () {
        this.cbAuthService.socialLogin('github').pipe(takeUntil(this.destroy$)).subscribe();
    };
    CreateAccountComponent.prototype.googlLogin = function () {
        this.cbAuthService.socialLogin('google-oauth2').pipe(takeUntil(this.destroy$)).subscribe();
    };
    CreateAccountComponent.prototype.linkedinLogin = function () {
        this.cbAuthService.socialLogin('linkedin').pipe(takeUntil(this.destroy$)).subscribe();
    };
    // facebook() {
    //   this.cbAuthService.socialLogin('facebook').pipe(takeUntil(this.destroy$)).subscribe();
    // }
    CreateAccountComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.captchaInterval);
        this.destroy$.next();
        this.destroy$.complete();
    };
    return CreateAccountComponent;
}());
export { CreateAccountComponent };
