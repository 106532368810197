import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil, throttleTime, distinctUntilChanged } from 'rxjs/operators';
import { to } from 'await-to-js';

import { CbAuthService } from '../../services/cb-auth.service';
import { regexPattern, CustomValidators } from '../../shared/custom-validations/custom-validations';
import { CbCreateAccountPageContent } from './create-account-content';
import { CbLoginConfig } from '../../services/cb-login.config';
import { CbRecaptchaService } from '../../services/cb-recpatcha.service';
import { CbBackendService } from '../../services/cb-backend.service';
import { CbAuthURLService } from '../../services/cb-auth-url.service';
import { CbTrainingAppService } from '../../services/cb-training-app.service';
import { CbHttpErrorsService } from '../../services/cb-http-errors.service';
import { CbFullstoryService } from '../../services/cb-fullstory.service';
import { getFriendlyErrorMessage } from './features';

@Component({
  selector: 'cb-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
  form: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  username: FormControl;
  password: FormControl;
  email: FormControl;
  destroy$ = new Subject();
  submitted = false;
  errorField: string;
  errorMessage: string;
  successMessage: string;
  formLoading = false;
  formObserver$: Observable<any>;

  captchaToken = null;
  captchaInterval: any;
  recaptchTokenError = false;
  recaptchaLoading = true;

  cbCreateAccountContent = new CbCreateAccountPageContent();
  isCheckboxInvalid: boolean;

  constructor(
    private fb: FormBuilder,
    private cbAuthService: CbAuthService,
    private cbBackendService: CbBackendService,
    private cbRecaptchaService: CbRecaptchaService,
    private cbConfigLogin: CbLoginConfig,
    private cbAuthURLService: CbAuthURLService,
    private cbTrainingAppService: CbTrainingAppService,
    private cbHttpErrorsService: CbHttpErrorsService,
    private cbFullstoryService: CbFullstoryService
  ) {}

  ngOnInit() {
    this.fetchRecaptchaToken();

    this.captchaInterval = setInterval(() => {
      this.fetchRecaptchaToken();
    }, 60000);

    this.initializeForm();
    this.handleFormChanges();
  }

  fetchRecaptchaToken() {
    this.recaptchaLoading = true;

    this.cbRecaptchaService
      .getCaptchaToken('secreateAccountviceDesk')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.recaptchaLoading = false;
          this.captchaToken = data;

          if (!data) {
            this.handleRecaptchaError();
            return;
          }
        },
        (error) => {
          console.log(error);
          this.handleRecaptchaError();
          return;
        }
      );
  }

  handleRecaptchaError() {
    this.recaptchTokenError = true;
  }

  initializeForm(): void {
    this.form = this.fb.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.pattern(regexPattern.namePattern),
          CustomValidators.noWhitespaceValidator,
          Validators.minLength(1),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.pattern(regexPattern.namePattern),
          CustomValidators.noWhitespaceValidator,
          Validators.minLength(1),
        ],
      ],
      username: [
        '',
        [
          Validators.required,
          CustomValidators.noStartWithNumber,
          Validators.pattern(regexPattern.username),
          Validators.minLength(3),
          Validators.maxLength(60),
        ],
      ],
      email: ['', [Validators.required, Validators.pattern(regexPattern.email)]],
      password: ['', [Validators.required, Validators.pattern(regexPattern.strongPassword)]],
    });

    this.firstName = this.form.get('firstName') as FormControl;
    this.lastName = this.form.get('lastName') as FormControl;
    this.username = this.form.get('username') as FormControl;
    this.email = this.form.get('email') as FormControl;
    this.password = this.form.get('password') as FormControl;
  }

  handleFormChanges(): void {
    const debouncePeriod = 500;
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$), throttleTime(debouncePeriod), distinctUntilChanged())
      .subscribe(() => {
        this.setGenericError({});
      });
  }

  // * NoContinue, means we will use a invalid State,
  // so the user will be redirecte to Login Page in order to continue
  // * NoContinue: Allow to send Email Verification on Create Account time
  // before a valid contine-State (auth0) is generated
  handleNoContinue() {
    if (this.cbTrainingAppService.IsTrainingCheckoutFlow()) {
      return true;
    }

    return false;
  }

  async submit() {
    const { state } = this.cbAuthURLService.getAllURLParams();
    const authorizeURL = this.cbAuthURLService.getReturnPath();
    const noContinue = this.handleNoContinue();

    const user = {
      username: this.username.value,
      email: this.email.value,
      password: this.password.value,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      captchaToken: this.captchaToken,
      state,
      noContinue,
      returnTo: authorizeURL,
    };
    this.submitted = true;

    this.setGenericError({});

    if (!this.form.valid) {
      return;
    }

    this.formLoading = true;

    const [err, userResponse] = await to(this.cbBackendService.createAccount(user).toPromise());
    if (err) {
      this.fetchRecaptchaToken();
      const errorMessage = this.cbHttpErrorsService.GetErrorMessage(err);
      this.errorMessage = getFriendlyErrorMessage(errorMessage);
      this.cbFullstoryService.fsError(this.errorMessage);
      this.formLoading = false;
      return;
    }

    this.sendCreateAccountEvent();
    this.onCreateAccountSuccess(userResponse);
  }

  sendCreateAccountEvent() {
    const payload = {
      username: this.username.value,
      email: this.email.value,
      displayName: `${this.firstName.value} ${this.lastName.value}`,
    };
    this.cbFullstoryService.sendEvent('create_account', payload);
  }

  disableSubmit(): boolean {
    if (this.form.invalid) {
      return true;
    }

    const { firstName, lastName, username, password, email } = this.form.value;

    const isAllFieldsProvided: boolean = email && firstName && lastName && password && username && this.captchaToken;

    if (!isAllFieldsProvided) {
      return true;
    }

    if (this.submitted && (this.form.invalid || this.formLoading)) {
      return true;
    }

    return false;
  }

  onCreateAccountSuccess(response) {
    if (!response) {
      this.formLoading = false;
      this.errorMessage = 'Something went wrong!';
      return;
    }

    if (response.error) {
      this.formLoading = false;
      this.errorMessage = response.message;
      return;
    }

    const isAuth0App = this.cbConfigLogin.cbAuthCentralizedLogin;

    if (isAuth0App) {
      // @info: we keep loading up to auth0 rule makes the redirection
      this.handleCreateAccountOnAuth0App();
      return;
    }

    this.formLoading = false;
    this.handleCreateAccountAsAngularModule(response);
    return;
  }

  handleCreateAccountOnAuth0App() {
    // @Info: Login again we are running all Auth0 rules (Pipeline)
    // this action redirects to sso-static-app.com/cb/create-account-email' in the current tab
    this.cbAuthService.login(this.username.value, this.password.value).subscribe(
      () => {},
      () => {
        this.formLoading = false;
        this.setGenericError({ errorMessage: 'There was an error creating your account.' });
        this.cbFullstoryService.fsError('There was an error creating your account.');
      }
    );
  }

  handleCreateAccountAsAngularModule(user) {
    this.cbAuthService.navigateTo('/cb/create-account-email', { queryParams: { email: user.email } });
  }

  onCreateAccountError(error: any) {
    this.formLoading = false;
    this.handleServerSideError(error);
  }

  handleServerSideError(error) {
    this.setGenericError(error);
  }

  setGenericError({ errorField = null, errorMessage = null }): void {
    if (errorMessage) {
      this.cbFullstoryService.fsError(errorMessage);
    }
    this.errorField = errorField;
    this.errorMessage = errorMessage;
  }

  githubLogin() {
    this.cbAuthService.socialLogin('github').pipe(takeUntil(this.destroy$)).subscribe();
  }

  googlLogin() {
    this.cbAuthService.socialLogin('google-oauth2').pipe(takeUntil(this.destroy$)).subscribe();
  }

  linkedinLogin() {
    this.cbAuthService.socialLogin('linkedin').pipe(takeUntil(this.destroy$)).subscribe();
  }
  // facebook() {
  //   this.cbAuthService.socialLogin('facebook').pipe(takeUntil(this.destroy$)).subscribe();
  // }

  ngOnDestroy() {
    clearInterval(this.captchaInterval);
    this.destroy$.next();
    this.destroy$.complete();
  }
}
